<template>
    <div class="fav-list-container">
        <div class="item-container">
            <ion-searchbar
                ref="searchText"
                spellcheck="false"
                type="text"
                role="search"
                :placeholder="mode == 'operator' ? 'Search Operator' : 'Search Routes'"
                v-model="searchTerm"
                @keyup.enter="onSearchKeyEntered($event)"
            ></ion-searchbar>
        </div>
        <ion-content class="list-container">
            <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)" v-if="routeMode !== 'trackingBus'">
                <ion-refresher-content> </ion-refresher-content>
            </ion-refresher>

            <ion-list class="scroller-container" v-if="user && filterList.length > 0">
                <RecycleScroller
                    class="no-zoom-el scroller"
                    :items="filterList"
                    :item-size="47"
                    :buffer="400"
                    key-field="company_id"
                    v-slot="{ item }"
                >
                    <ion-item
                        detail="false"
                        button
                        v-bind:key="item.company_id"
                        @click="onItemListClick($event, item)"
                        class="item-operator"
                        v-if="routeMode !== 'trackingBus'"
                        :color="!checkBoldTextFavMode(item) ? 'normal' : 'tertiary'"
                    >
                        <ion-icon :src="getIconSrc(item)" aria-hidden="true" class="left-space icon-heart"></ion-icon>
                        <ion-label
                            class="left-space"
                            v-bind:class="{
                                'text-normal': !checkBoldTextFavMode(item),
                                'text-white': checkBoldTextFavMode(item),
                            }"
                            :aria-label="checkBoldTextFavMode(item) ? `${item.name}, favorite operator` : item.name"
                            >{{ item.name }}</ion-label
                        >
                        <ion-icon name="chevron-forward-outline" aria-hidden="true" v-if="!user.fav_operator_id"></ion-icon>
                    </ion-item>
                    <ion-item
                        button
                        detail="false"
                        v-bind:key="item.company_id"
                        @click="onItemListClick($event, item)"
                        class="item-operator"
                        v-if="routeMode == 'trackingBus'"
                        :color="!checkBoldTextFavMode(item) ? 'normal' : 'tertiary'"
                    >
                        <ion-label
                            class="left-space"
                            v-bind:class="{
                                'text-normal': !checkBoldTextFavMode(item),
                                'text-white': checkBoldTextFavMode(item),
                            }"
                            :aria-label="checkBoldTextFavMode(item) ? `${item.name}, selected operator` : item.name"
                            >{{ item.name }}</ion-label
                        >
                        <ion-icon name="chevron-forward-outline" aria-hidden="true"></ion-icon>
                    </ion-item>
                </RecycleScroller>
            </ion-list>
            <ion-text color="primary" v-else class="no-search-text" role="alert">
                <h2>No results found</h2>
            </ion-text>
        </ion-content>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import favApi from '../../util/apis/favorite'
import appService from '../../util/services/appService'
import { modalController } from '@ionic/vue'
import UserPrivacyModal from '../../views/modal/UserPrivacyModal'
import companyApi from '../../util/apis/company'
import authApi from '../../util/apis/auth'
import FavoriteRoutes from '@/components/favorite/FavoriteRoutes.vue'
import useNavigator from '@/composables/useNavigator'
import helperSrv from '../../util/helper'

export default {
    name: 'FavoriteList',
    props: {
        isHiddenCheckBtn: Boolean,
        isHiddenNavBtn: Boolean,
        isHiddenSelectBtn: Boolean,
        mode: String,
        selectMode: String,
        dataList: Array,
        hideLoading: Function,
        showLoading: Function,
        routeMode: String,
    },
    data: function () {
        return {
            heartIcon: require('../../assets/heart.svg'),
            heartOutLineIcon: require('../../assets/heart-outline.svg'),
            checkmarkOutline: require('../../assets/checkmark-outline.svg'),
            searchTerm: '',
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        computedMode() {
            return this.mode
        },
        filterList() {
            return this.dataList.filter((operator) => {
                return operator.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            })
        },
    },
    methods: {
        onSearchKeyEntered($event) {
            console.log($event)
        },
        async doRefresh(event) {
            await companyApi.getActiveCompanies()
            event.target.complete()
        },
        onItemListClick(event, fav) {
            if (this.routeMode === 'trackingBus') {
                this.goToFavRouteList(fav)
            } else {
                this.selectFavOperator(fav)
            }
        },
        async selectFavOperator(fav) {
            const operatorIdFav = this.user.fav_operator_id
            const operatorIdSelect = parseInt(fav.company_id)
            if (!operatorIdFav) {
                if (await this.checkAndSaveOperatorFav(operatorIdSelect)) {
                    this.goToFavRouteList(fav)
                }
            } else if (operatorIdFav !== operatorIdSelect) {
                const operatorWillChangeName = appService.findCompanyName(operatorIdSelect, this.dataList)
                if (await this.presentChangeOperatorAlert(fav, operatorWillChangeName)) {
                    await this.checkAndSaveOperatorFav(operatorIdSelect)
                }
            }
            appService.storeDefaultLocation()
        },

        async checkAndSaveOperatorFav(operatorId) {
            if (await this.checkUserPrivacyAgreement(operatorId)) {
                await this.saveOperatorFav(operatorId)
                await authApi.refreshUserInfo()
                return true
            } else {
                return false
            }
        },
        async saveOperatorFav(operatorId) {
            await favApi.updateOperatorFavorite(operatorId)
            this.$store.dispatch('saveOperatorFav', operatorId)
        },
        getIconSrc(item) {
            return this.getOperatorIcon(item)
        },
        async checkUserPrivacyAgreement(operatorId) {
            const companies = this.dataList

            const selectedOperator = companies.find((c) => c.company_id === operatorId)
            if (selectedOperator.privacy_text) {
                const countUserChecked = await favApi.countUserCheckedPrivacy(operatorId)
                if (countUserChecked.data.countUserCheckedPrivacy === 0) {
                    return await this.openPrivacyTextModal(selectedOperator.privacy_text, operatorId)
                }
            }
            return true
        },
        async openPrivacyTextModal(text, operator_id) {
            let modal = await modalController.create({
                component: UserPrivacyModal,
                cssClass: 'user-privacy-modal',
                componentProps: {
                    privacyText: text,
                    favApi: favApi,
                    operator_id: operator_id,
                },
            })

            // show the modal
            await modal.present()

            // wait to see if i get a response
            let modalResponse = await modal.onDidDismiss()
            return modalResponse.data
        },
        async presentChangeOperatorAlert(fav, operatorName) {
            return (
                'ok' ===
                (await appService.presentAlert({
                    header: 'Warning',
                    message: `Did you want to change your favorite operator to ${operatorName}?`,
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                        },
                        {
                            text: 'OK',
                            role: 'ok',
                        },
                    ],
                }))
            )
        },
        goToFavRouteList(fav) {
            this.openSearchRouteModal(fav)
        },
        async openSearchRouteModal(fav) {
            let modal = await modalController.create({
                component: FavoriteRoutes,
                cssClass: 'favorite-route-modal',
                componentProps: {
                    routeMode: this.routeMode,
                    operatorId: fav.company_id,
                },
            });

            // Disable screen reader access previous page
            helperSrv.setElementAttribute("fav-list-page", "aria-hidden", true);

            // show the modal
            await modal.present()

            // wait to see if i get a response
            let modalResponse = await modal.onDidDismiss()

            // Enable screen reader again
            helperSrv.removeElementAttribute("fav-list-page", "aria-hidden");

            if (modalResponse.data && modalResponse.data.backToRoot) {
                if (this.routeMode == 'trackingBus') {
                    modalController.dismiss()
                } else {
                    useNavigator.replaceToLiveTracking()
                }
            }
        },
        getOperatorIcon(fav) {
            if (this.user.fav_operator_id === fav.company_id) {
                return this.heartIcon
            }
            return this.heartOutLineIcon
        },
        checkBoldTextFavMode(fav) {
            if (this.routeMode === 'trackingBus') {
                return (
                    _.some(this.user.favorite_routes, {
                        operator_id: fav.company_id,
                    })
                )
            } else {
                return fav.company_id === this.user.fav_operator_id
            }
        },
    },
}
</script>

<style scoped>
.fav-list-container {
    position: relative;
    width: 100%;
    height: calc(100% - 44px - env(safe-area-inset-top));
}
.list-container {
    top: 70px;
    height: calc(100% - 70px);
}
.item-container {
    border: 1.5px solid #979797;
    border-radius: 6px;
    width: calc(100% - 32px);
    display: flex;
    top: 12px;
    flex-direction: row;
    align-items: center;
    position: absolute;
    margin-left: 20px;
    padding-bottom: 1px;
}
.search-icon {
    font-size: 24px;
}

.item-operator {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    --min-height: 48px;
    border-bottom: 1px solid #1073ba;
    height: 47px;
    cursor: pointer;
}
.scroller-container {
    height: 100%;
}
.scroller {
    height: 100%;
}
.note-text {
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.6;
    margin-top: 12px;
}
ion-item {
    --border-color: #1073ba;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    --min-height: 48px;
    --padding-start: 0px;
}
.bold-style {
    font-weight: bold;
    color: black;
}
.icon-heart {
    width: 32px;
    height: 24px;
    margin-right: 12px;
    color: #fd7c25;
}
.item-container input {
    outline: none;
    border: none;
}
.text-normal {
    color: var(--ion-color-text-normal-dark);
}
.text-dark {
    color: var(--ion-color-text-normal-dark);
    font-weight: bold;
}
.text-white {
    color: var(--ion-color-normal-contrast);
}
ion-searchbar {
    --border-radius: 4px;
    --box-shadow: none;
    padding: 0 0 0 0;
    width: 100%;
    max-height: 40px;
    --background: white;
}
ion-searchbar .searchbar-input-container .searchbar-clear-button {
    width: 20px;
}
.left-space {
    padding-left: 22px;
}
</style>

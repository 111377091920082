<template>
    <ion-page>
        <div class="header-container">
            <ion-label class="title-modal">{{ $t('privacy') }}</ion-label>
        </div>
        <ion-content class="ion-padding">
            <ion-text class="privacy-text">{{ privacyText }}</ion-text>
        </ion-content>
        <div class="footer-container">
            <ion-button @click="dismissModal()" color="primary" fill="clear">Cancel</ion-button>
            <ion-button @click="savePrivacy" class="text-bold" color="primary" fill="clear">Agree</ion-button>
        </div>
    </ion-page>
</template>

<script>
import { modalController } from '@ionic/vue'

export default {
    name: 'UserPrivacyModal',
    props: {
        privacyText: String,
        operator_id: Number,
        favApi: Object,
    },
    data: function () {
        return {}
    },

    methods: {
        dismissModal() {
            modalController.dismiss(false)
        },
        async savePrivacy() {
            const operatorIdParam = this.operator_id
            await this.favApi.saveUserPrivacy(operatorIdParam)
            modalController.dismiss(true)
        },
    },
}
</script>
<style scoped>
.privacy-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    letter-spacing: 0.25px;
}
.title-modal {
    color: white;
    font-weight: bold;
}
.text-bold {
    font-weight: bold;
}
ion-button {
    --padding-start: 0px;
}
</style>


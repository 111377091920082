<template>
    <ion-page id="fav-list-page">
        <ion-header>
            <ion-toolbar mode="ios">
                <ion-buttons slot="start" v-if="routeMode === 'trackingBus'">
                    <ion-button @click="goBack" aria-label="Go Back">
                        <ion-icon name="chevron-back-outline" class="no-zoom-el arrow-back-icon"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="ion-text-capitalize">
                    <ion-text>
                        <span
                            role="heading"
                            class="no-zoom-el no-margin"
                            id="top-header-title"
                            tabindex="-1"
                            ref="favHeaderTitle"
                            >{{ routeMode === 'trackingBus' ? $t('select-operator') : $t('favorite-operator') }}</span
                        >
                    </ion-text>
                </ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
        <favorite-list
            v-if="loaded"
            ref="favList"
            isHiddenCheckBtn
            :mode="'operator'"
            selectMode="single"
            :routeMode="routeMode"
            :dataList="computedSortCompanies"
            :hideLoading="hideLoading"
            :showLoading="showLoading"
        ></favorite-list>
    </ion-page>
</template>

<script>
import _ from 'lodash'
import { defineComponent, ref, onMounted, computed } from 'vue'
import FavoriteList from './FavoriteList'
import companyApi from '../../util/apis/company'
import { useStore } from 'vuex'
import { modalController } from '@ionic/vue'
import appService from "../../util/services/appService";
import { onIonViewDidEnter } from '@ionic/vue'

export default defineComponent({
    name: 'Favorite',
    components: {
        FavoriteList,
    },
    props: {
        routeMode: String,
    },
    setup(props) {
        const favList = ref(null);
        const favHeaderTitle = ref(null);

        const store = useStore()
        const loading = ref(false)
        const loaded = ref(false)

        const routeMode = props.routeMode
        const companies = ref([])
        const user = computed(() => store.getters.user)
        const computedSortCompanies = computed(() => {
            let result = []
            if (routeMode !== 'trackingBus') {
                result = sortFavCompanies(companies.value, user.value)
            } else {
                result = sortTrackingCompanies(companies.value, user.value)
            }
            return result
        })
        const getActiveCompanies = async () => {
            loading.value = true
            companies.value = await companyApi.getActiveCompanies()
            loading.value = false
            loaded.value = true
        }

        const sortFavCompanies = (companies, user) => {
            let sortCompanies = []
            const cloneCompanies = _.cloneDeep(companies)
            if (user && user.fav_operator_id) {
                const favOperatorId = user.fav_operator_id
                const favCompany = cloneCompanies.find((company) => company.company_id === favOperatorId)
                if (favCompany) {
                    sortCompanies.push(favCompany)
                }
                _.remove(cloneCompanies, function (item) {
                    return item.company_id === favOperatorId
                })
            }
            return sortCompanies.concat(cloneCompanies)
        }

        const sortTrackingCompanies = (companies, user) => {
            let sortCompanies = []
            const cloneCompanies = _.cloneDeep(companies)
            const operatorGroup = _.groupBy(user.favorite_routes, function (route) {
                return route.operator_id
            })
            const operatorIds = Object.keys(operatorGroup)
            // add fav operator id to first index in ids array
            if (user && user.fav_operator_id) {
                _.remove(operatorIds, function (id) {
                    return id == user.fav_operator_id
                })
                operatorIds.unshift(user.fav_operator_id.toString())
            }

            for (let i = 0; i < cloneCompanies.length; i++) {
                if (operatorIds.includes(cloneCompanies[i].company_id.toString())) {
                    sortCompanies.push(cloneCompanies[i])
                }
            }
            _.remove(cloneCompanies, function (item) {
                return operatorIds.includes(item.company_id.toString())
            })
            return sortCompanies.concat(cloneCompanies)
        }

        onMounted(() => {
            appService.hideSplashScreen();
            getActiveCompanies();
        })

        onIonViewDidEnter(() => {
            setTimeout(() => {
                favHeaderTitle.value.focus()
            }, 100)
        })

        const hideLoading = () => {
            loading.value = false
        }

        const goBack = () => {
            modalController.dismiss()
        }

        const showLoading = () => {
            loading.value = false
        }
        return {
            favList,
            loading,
            loaded,
            companies,
            user,
            computedSortCompanies,
            favHeaderTitle,
            hideLoading,
            goBack,
            showLoading,
            primaryColor: "#003C69"
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
<style>
ion-toolbar {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
}
#top-header-title:focus {
    outline: none;
}
</style>
